@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  body {
    @apply font-satoshi font-normal text-base text-body bg-whiten relative z-1;
  }
}

@layer components {
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .chat-height {
    @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
  }
  .inbox-height {
    @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
  }
}

.react-date-picker__wrapper{
  border: none!important;
}